type RegionData = {
  name: string;
  storefront: string;
  additionalLanguages: any[];
};

export const CF_USER_COUNTRY = 'cf_user_country';

export const DISMISS_LANGUAGE_SWITCHER = 'DISMISS_LANGUAGE_SWITCHER';

export const regionCountries: Record<string, RegionData> = {
  'en-us': {
    name: 'US',
    storefront: 'en-us',
    additionalLanguages: [],
  },
  'en-ca': {
    name: 'CA',
    storefront: 'en-ca',
    additionalLanguages: [],
  },
  'en-au': {
    name: 'AU',
    storefront: 'en-au',
    additionalLanguages: [],
  },
  'en-nz': {
    name: 'NZ',
    storefront: 'en-nz',
    additionalLanguages: [],
  },
  'en-gb': {
    name: 'GB',
    storefront: 'en-gb',
    additionalLanguages: [],
  },
  'en-eu': {
    name: 'EUR',
    storefront: 'en-eu',
    additionalLanguages: [],
  },
};

export const browserLanguageMapping: Record<string, string> = {
  'en-us': 'en-us',
  'en-ca': 'en-ca',
  'en-au': 'en-au',
  'en-gb': 'en-gb',
  'en-nz': 'en-nz',
  'en-eu': 'en-eu',
  ca: 'en-ca',
  cs: 'en-eu',
  da: 'en-eu',
  de: 'en-eu',
  'de-at': 'en-eu',
  'de-de': 'en-eu',
  'de-li': 'en-eu',
  'de-lu': 'en-eu',
  'de-ch': 'en-eu',
  en: 'en-us',
  el: 'en-eu',
  es: 'en-eu',
  et: 'en-eu',
  fi: 'en-eu',
  fr: 'en-eu',
  'fr-be': 'en-eu',
  'fr-ca': 'en-ca',
  'fr-fr': 'en-eu',
  'fr-lu': 'en-eu',
  'fr-mc': 'en-eu',
  'fr-ch': 'en-eu',
  hr: 'en-eu',
  nl: 'en-eu',
  'nl-be': 'en-eu',
  no: 'en-eu',
  pl: 'en-eu',
  ro: 'en-eu',
  sk: 'en-eu',
  sr: 'en-eu',
  us: 'en-us',
};
