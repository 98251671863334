import { SUPPORTED_LOCALES } from '@lib/locales';

type Language = (typeof LANGUAGE_MAP)[number];

export type LocaleMapData = { lang: Language[]; img: string; url?: string[] };

export type LocaleMap = Record<string, LocaleMapData>;

interface LocaleData {
  name: string;
  shortName: string;
  flag: string;
}

export const LANGUAGE_MAP = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'jp', 'nl', 'no', 'pl', 'pt', 'sv'] as const;

export const LOCALES_MAP: Record<string, LocaleData> = {
  [SUPPORTED_LOCALES.EN_US]: {
    name: 'United States',
    shortName: 'US',
    flag: '🇺🇸',
  },
  [SUPPORTED_LOCALES.EN_CA]: {
    name: 'Canada (English)',
    shortName: 'CA (EN)',
    flag: '🇨🇦',
  },
  [SUPPORTED_LOCALES.FR_CA]: {
    name: 'Canada (Français)',
    shortName: 'CA (FR)',
    flag: '🇨🇦',
  },
  [SUPPORTED_LOCALES.EN_AU]: {
    name: 'Australia',
    shortName: 'AU',
    flag: '🇦🇺',
  },
  [SUPPORTED_LOCALES.EN_NZ]: {
    name: 'New Zealand',
    shortName: 'NZ',
    flag: '🇳🇿',
  },
  [SUPPORTED_LOCALES.EN_GB]: {
    name: 'British English',
    shortName: 'GB',
    flag: '🇬🇧',
  },
  // [SUPPORTED_LOCALES.DE_EU]: {
  //   name: 'Europe (German)',
  //   shortName: 'DE',
  // },
  [SUPPORTED_LOCALES.EN_EU]: {
    name: 'Europe (English)',
    shortName: 'EU',
    flag: '🇪🇺',
  },
  // [SUPPORTED_LOCALES.ES_EU]: {
  //   name: 'Europe (Spanish)',
  //   shortName: 'ES',
  // },
  // [SUPPORTED_LOCALES.FR_EU]: {
  //   name: 'Europe (French)',
  //   shortName: 'FR',
  // },
  // [SUPPORTED_LOCALES.IT_EU]: {
  //   name: 'Europe (Italian)',
  //   shortName: 'IT',
  // },
  // [SUPPORTED_LOCALES.NL_EU]: {
  //   name: 'Europe (Dutch)',
  //   shortName: 'NL',
  // },
};

export const MAIN_LOCALES_MAP: LocaleMap = {
  US: { lang: ['en'], img: '🇺🇸' },
  UK: { lang: ['en'], img: '🇬🇧' },
  JP: { lang: ['jp'], img: '🇯🇵', url: ['https://thetileapp.jp'] },
  AU: { lang: ['en'], img: '🇦🇺' },
};

export const SECONDARY_LOCALES_MAP: LocaleMap = {
  AT: { lang: ['de', 'en'], img: '🇦🇹' },
  BE: { lang: ['de', 'fr', 'nl', 'en'], img: '🇧🇪', url: ['https://be.tile.com/nl'] },
  CA: { lang: ['en'], img: '🇨🇦' },
  CH: { lang: ['de', 'fr', 'it', 'en'], img: '🇨🇭' },
  DE: { lang: ['de', 'en'], img: '🇩🇪' },
  DK: { lang: ['da', 'en'], img: '🇩🇰', url: ['https://dk.tile.com/dk'] },
  ES: { lang: ['es', 'en'], img: '🇪🇸' },
  EU: { lang: ['en'], img: '🇪🇺', url: ['https://ie.tile.com'] },
  FI: { lang: ['fi', 'en'], img: '🇫🇮' },
  FR: { lang: ['fr', 'en'], img: '🇫🇷' },
  IE: { lang: ['en'], img: '🇮🇪' },
  IT: { lang: ['it', 'en'], img: '🇮🇹' },
  NZ: { lang: ['en'], img: '🇳🇿' },
  PL: { lang: ['pl', 'en'], img: '🇵🇱' },
  PT: { lang: ['pt', 'en'], img: '🇵🇹' },
  SE: { lang: ['sv', 'en'], img: '🇸🇪', url: ['https://se.tile.com/se'] },
  NL: { lang: ['nl', 'en'], img: '🇳🇱' },
  NO: { lang: ['no', 'en'], img: '🇳🇴' },
};

export const CF_SITE_LOCALE = 'cf_site_locale';
